import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Section from "../Section";
import useMouseInfo from "../../utils/hooks/useMouse";
import useScrollInfo from "../../utils/hooks/useScroll";

function HomeDefilement2() {
  const { scrollAmountY } = useScrollInfo();
  const { normalizedPos } = useMouseInfo();

  const [sectionRef, setSectionRef] = useState();
  const [diference, setDiference] = useState(0);

  useEffect(() => {
    sectionRef && setDiference(scrollAmountY - sectionRef.current.offsetTop);
  }, [scrollAmountY]);

  // useEffect(() => {}, [diference]);

  const handleSectionRef = (ref) => {
    setSectionRef(ref);
  };

  return (
    <Section
      className="defilement"
      handleSectionRef={handleSectionRef}
      animate={{
        backgroundColor: diference > 450 ? "#0b0c0cff" : "#0b0c0c00",
        transition: {
          duration: 0.2,
        },
      }}
    >
      <motion.div
        className="growing gogles"
        animate={{
          scale: diference >= -1000 && diference < 450 ? 0.1 : 1.2,
          x:
            normalizedPos &&
            window.innerWidth * normalizedPos.x - window.innerWidth / 2 - 130,
          y:
            normalizedPos &&
            window.innerHeight * normalizedPos.y - window.innerHeight / 2,
          transition: {
            ease: "backOut",
            duration: 0.3,
          },
        }}
      ></motion.div>
      <h2 className="text__noir">
        L'EXPLORATION, NOTRE ADN <br /> PÉDAGOGIQUE
      </h2>
    </Section>
  );
}

export default HomeDefilement2;
