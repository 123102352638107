import React from "react";
import { Button } from "./Button";

export const FormationCard = ({ illustration, data, to }) => {
  return (
    <div className="formation__card">
      <img src={illustration} alt="" className="card__illustration" />
      <div className="card__content">
        {data.infos && (
          <span className="formation__duration">{data.infos.duration}</span>
        )}
        <h5>{data.secondary}</h5>
        <h4>{data.name}</h4>
        {data.infos && <p>{data.infos.prerequisites_min}</p>}
        <Button text={to.text} to={to.link} />
      </div>
    </div>
  );
};
