import React from "react";
import { Formation } from "./models/Formation";
import onmData from "../../utils/data/onm_formation_data.json";
function ONM() {
  return (
    <React.Fragment>
      <Formation data={onmData} />
    </React.Fragment>
  );
}

export default ONM;
