import React from "react";

function Modules({ module }) {
  return (
    <div className="module text__creme">
      <h4>Objectifs pédagogiques</h4>

      <div className="obj">
        <p>{module.pedagogique}</p>
      </div>

      <h4>Modules</h4>

      <div className="modules">
        <ul>
          {module.real_modules.map((module) => (
            <li>{module}</li>
          ))}
        </ul>
      </div>

      {module.outils && (
        <div className="outils">
          <h4>Liste des outils</h4>
          <ul>
            {module.outils.map((outil) => (
              <li>{outil}</li>
            ))}
          </ul>
        </div>
      )}

      {module.outils && (
        <div className="logiciels">
          <h4>Liste des logiciels</h4>
          <ul>
            {module.logiciels.map((logiciel) => (
              <li>{logiciel}</li>
            ))}
          </ul>
        </div>
      )}

      {module.outils && (
        <div className="formats">
          <h4>Liste des formats</h4>
          <p>{module.formats.map((outil) => outil + "/")}</p>
        </div>
      )}
    </div>
  );
}

export default Modules;
