import React from "react";
import { ModulesAPI } from "./models/ModulesAPI";
import devData from "../../utils/data/developpement.json";
function Confiance() {
  return (
    <React.Fragment>
      <ModulesAPI data={devData} />
    </React.Fragment>
  );
}

export default Confiance;
