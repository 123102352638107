import React, { useEffect } from "react";
import { Image } from "../Image";
import Section from "../Section";
import { HeadingPar } from "../TextHelper";

import formateurData from "../../utils/data/formateurs.json";

import illuEspaces from "../../lib/espaces-fond.svg";

export const Identity = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(formateurData);
  }, []);

  return (
    <main>
      <Section id="identity__header">
        <div className="header__content">
          <h1>UN ÉCOSYSTÈME FACILITATEUR</h1>
        </div>
      </Section>

      <Section className="coordinateur">
        <HeadingPar
          heading="DES COORDINATEURS PÉDAGOGIQUES  A 360°"
          par="Nous sommes alertes aux tendances pédagogiques actuelles et aux dispositifs facilitateurs. Nous travaillons sur l’adéquation des moyens pédagogiques, techniques et d’encadrement. "
        />
        <Image
          src="https://api.maignanb.com/static/tnf-static-images/coordination/coordination-4.jpeg"
          alt=""
        />
      </Section>
      <Section className="formateurs">
        <HeadingPar
          heading="DES FORMATEURS EN UPDATE PERMANENT "
          par="Nous recrutons des formateurs expérimentés en veille technologique dans le sillon de nouvelles tendances professionnelles et des métiers émergents, Ils sont passionnés pour insuffler le goût d'apprendre."
        />
        {/*Trombi des formateurs*/}

        <div className="formateurs__trombi">
          {formateurData.map((data, i) => (
            <div className="formateur__card" key={i}>
              <Image src={data.imgUrl} alt={data.name + " " + data.desc} />
              <div className="formateur__overlay">
                <h4>{data.name}</h4>
                <p>{data.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </Section>
      <Section className="espaces__identity">
        <HeadingPar heading="DES  ESPACES NUMÉRIQUES TENDANCE" />

        <div className="espaces__preview">
          <img src={illuEspaces} alt="Nos Espace" />
          <h3>LE PONT</h3>
          <h3>L'ILOT</h3>
        </div>
        <p>
          Nous avons imaginé un environnement de formation idéal avec du
          matériel de pointe toujours plus adapté à la formation suivie au sein
          d'espaces sécurisés et équipés avec les moyens spécifiques de
          l'univers professionnel choisi.
        </p>
      </Section>
      <Section className="modality_technical">
        <div className="technical_modality__list heading_par">
          <div className="technical">
            <h3 className="text__blue">Des moyens techniques performants</h3>
            <ul>
              <li>Ordinateurs et tablettes graphiques dernière génération</li>
              <li>Plateau de montage vidéo</li>
              <li>Appareils de captation vidéo et son</li>
              <li>Studio d’enregistrement</li>
              <li>Studio photographie</li>
              <li>Studio fond vert </li>
              <li>LAB imprimerie tout support</li>
            </ul>
          </div>
          <div className="modality">
            <h3 className="text__blue">Des modalités souples</h3>
            <h4 className="text__orange">En présentiel</h4>
            <ul>
              <li>Bénéficiants des nos atouts en termes d’espace et moyens </li>
              <li>
                Création d’un mail personnel permettant l’accès aux ressources
              </li>
              <li>
                Installation d’applications collaboratives : WhatsApp, Discord…
              </li>
            </ul>
            <h4 className="text__orange">En distanciel</h4>
            <ul>
              <li>
                Intégration de l'environnement GSuite et les solutions Google
                avec l'écosystème Google Cloud.
              </li>
              <li>
                Des salons de cours et visioconférence permettant de configurer
                des espaces virtuels de formation.
              </li>
              <li>
                Création d’un mail personnel permettant l’accès aux ressources à
                distance
              </li>
              <li>
                Un planning hebdomadaire de formations à distance est envoyé par
                mail
              </li>
              <li>
                Installation d’applications collaboratives : watsap, discord…
              </li>
              <li>Éventuelle mise à disposition d’ordinateurs</li>
            </ul>
          </div>
        </div>
      </Section>
      <Section>
        <div className="info_box">
          <h2 className="title_box">i</h2>
          <div className="engagement">
            <h4 className="text__orange">Notre engagement qualité</h4>
            <p>
              Vous accédez à un environnement de formation idéal avec du
              matériel de pointe toujours adapté à la formation suivie au sein
              d’espaces sécurisés, équipés avec les moyens spécifiques de
              l’univers professionnel choisi.
            </p>
            <p>Nous nous engageons pour que votre parcours soit unique :</p>
            <ul>
              <li>
                Vos besoins de formation sont identifiés, une réponse adaptée
                est apportée, le suivi pédagogique et d’évaluation est
                individualisé.
              </li>
              <li>
                Vous êtes soutenus par une personne ressource dans vos démarches
                administratives
              </li>
            </ul>
            <p>Dans le cadre de votre parcours, nous veillons à : </p>
            <ul>
              <li>
                L’adéquation des moyens pédagogiques, techniques et
                d’encadrement avec l’offre de formation qui vous est proposée.
              </li>
              <li>
                La configuration d’une équipe de formateurs expérimentés, en
                veille technologique dans le sillon de nouvelles tendances
                professionnelles et des métiers émergents.
              </li>
              <li>
                L’actualisation des tendances pédagogiques et des dispositifs
                facilitateurs.
              </li>
            </ul>
            <p>
              Grâce à votre évaluation nous mesurons la qualité et l’impact de
              chaque aspect de nos prestations et nous partageons ces
              évaluations avec l’ensemble de nos partenaires.
            </p>
          </div>
          <div className="access">
            <h4 className="text__orange">
              Accessibilite aux personnes handicapees
            </h4>
            <p>
              Toutes nos formations sont proposées dans des locaux adaptés au
              nombre de participants et aux personnes en situation de Handicap
              dans le respect des normes ERP et des consignes covid 19.
            </p>
            <p>
              Les personnes atteintes de handicap souhaitant suivre cette
              formation sont invitées à contacter directement notre Référent
              handicap par mail : referent-handicap@tnf-formation.fr, afin
              d’étudier ensemble les possibilités de suivre la formation.
            </p>
          </div>
        </div>
      </Section>
    </main>
  );
};
