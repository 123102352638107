import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { ReactComponent as MainLogo } from "../lib/main-logo.svg";
import { Letterbox } from "./Letterbox";

export const Navigation = () => {
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    setShowNav(false);
  }, []);

  const Nav = () => (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exite={{ opacity: 0 }}
      transition={{ delay: 0.05 }}
    >
      <div className="list__container">
        <ul>
          <li className="nav__items">
            <Link to="/" onClick={onToggleNav}>
              Notre identité
            </Link>
          </li>
          <li className="nav__items">
            <Link to="/identite" onClick={onToggleNav}>
              Notre pédagogie
            </Link>
          </li>
          <li className="nav__items">
            <Link to="/a-propos" onClick={onToggleNav}>
              Notre ecosysteme
            </Link>
          </li>
        </ul>
        <ul>
          <li>Nos formations</li>
          <li className="forms__items">
            <Link
              to="/formations/operateur-numerique-multimedia/modules/Acceuil"
              onClick={onToggleNav}
            >
              Opératrice / opérateur numérique multimédia
            </Link>
          </li>
          <li className="forms__items">
            <Link
              to="/formations/assistant-infographiste-audiovisuel/modules/Acceuil"
              onClick={onToggleNav}
            >
              Assistant infographiste audiovisuel
            </Link>
          </li>
          <li className="forms__items">
            <Link
              to="/formations/assistant-cadreur-monteur/modules/Acceuil"
              onClick={onToggleNav}
            >
              Assistant Cadreur/Monteur
            </Link>
          </li>
          <li className="forms__items">
            <Link
              to="/formations/module_API_Confiance_en_soi"
              onClick={onToggleNav}
            >
              Confiance en soi
            </Link>
          </li>
          <li className="forms__items">
            <Link
              to="/formations/DeveloppementVerbalEtNonVerbal"
              onClick={onToggleNav}
            >
              Communication verbal et non verbal
            </Link>
          </li>
        </ul>
      </div>
    </motion.nav>
  );

  const onToggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <React.Fragment>
      <Link to="/">
        <div className="main__logo" onClick={showNav ? onToggleNav : null}>
          <MainLogo />
        </div>
      </Link>
      <button className="nav__toggle" onClick={onToggleNav}>
        ME
        <br />
        NU
      </button>
      <Letterbox />

      {showNav && <Nav />}
    </React.Fragment>
  );
};
