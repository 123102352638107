import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import useScrollInfo from "../utils/hooks/useScroll";

export default function Section(props) {
  const { scrollAmountY } = useScrollInfo();
  const [factor, setFactor] = useState(0);

  const sectionRef = useRef();

  useEffect(() => {
    scrollAmountY &&
      setFactor(
        scrollAmountY /
          (sectionRef.current.offsetTop + sectionRef.current.clientHeight)
      );
  }, [scrollAmountY]);

  useEffect(() => {
    props.handleSectionRef && props.handleSectionRef(sectionRef);
  }, []);

  return (
    <motion.section
      className={props.className}
      ref={sectionRef}
      animate={props.animate && props.animate}
    >
      <AnimatePresence>
        <motion.div className="col">{props.children}</motion.div>
      </AnimatePresence>
    </motion.section>
  );
}
