import React from "react";

export const HeadingPar = ({ heading, par }) => {
  return (
    <div className="heading_par">
      <h2>{heading}</h2>
      {par && <p>{par}</p>}
    </div>
  );
};
