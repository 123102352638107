import React, { useEffect } from "react";
import Section from "../../Section";
import FormationModules from "../../sections/FormationModules";

export const Formation = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <Section>
        <h2 className="header__content">{data.secondary}</h2>
        <h1 className="header__content">{data.name}</h1>
      </Section>

      <Section>
        <p className="description">{data.desc}</p>

        <div className="info_box">
          <p className="title_box">Les +</p>
          <p>{data.upsides}</p>
        </div>
        <div className="formation__list">
          <h3> Objectifs pédagogiques</h3>
          <ul className="formation__objectifs">
            {data.goals.map((objectif) => (
              <li>{objectif}</li>
            ))}
          </ul>
          <h3>Dans l'exercice de ses missions, l'ONM sera capable de :</h3>
          <ul className="formation__mission">
            {data.missions.map((mission) => (
              <li>{mission}</li>
            ))}
          </ul>
          <h3>Pour faire évoluer ses compétences l'ONM sera capable de :</h3>
          <ul className="formation__evolve">
            {data.evolve.map((evoluer) => (
              <li>{evoluer}</li>
            ))}
          </ul>
        </div>

        <div className="info_box right">
          <h2 className="title_box">i</h2>
          <h6 className="text__orange">Les prérequis</h6>
          <ul>
            {data.infos.prerequisites.map((prerequisite) => (
              <li>{prerequisite}</li>
            ))}
          </ul>
          <h6 className="text__orange">La durée</h6>
          <p>{data.infos.duration}</p>
          <h6 className="text__orange">Les dates</h6>
          <p>{data.infos.date}</p>

          <h6 className="text__orange">Fin de la phase d'admission</h6>
          <p>{data.infos.admin}</p>
          <h6 className="text__orange">Nombre d'apprenants</h6>

          <p>{data.infos.effectifs}</p>
          <h6 className="text__orange">Nombre de stagiaire minimum</h6>
          <p>{data.infos.stagiaire}</p>
          <h6 className="text__orange">Tarifs</h6>
          <p>{data.infos.expences}</p>
        </div>
      </Section>

      <FormationModules modules={data.modules} />
    </main>
  );
};
