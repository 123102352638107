import React from "react";

export const Data = ({ className, value, label }) => {
  return (
    <div className={"data " + className}>
      <span className="number text__orange">{value}</span>
      <p className="desc">{label}</p>
    </div>
  );
};
