import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Button } from "./Button";

export const Letterbox = () => {
  const [showCont, setShowCont] = useState(false);

  useEffect(() => {
    setShowCont(false);
  }, []);

  const Cont = ({ onClick }) => (
    <motion.div
      className="bg__contact"
      initial={{ backgroundColor: "#0b0c0c00" }}
      animate={{ backgroundColor: "#0b0c0c88" }}
      transition={{ delay: 0.1 }}
    >
      <motion.div
        className="modal__contact"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exite={{ opacity: 0 }}
        transition={{ delay: 0.05 }}
      >
        <div className="contact__page__left text__creme">
          <h3>RETROUVEZ-NOUS</h3>

          <p>TREMPLIN NUMÉRIQUE FORMATION 23 rue Bénard 75014 Paris</p>
          <h3>NOS ESPACES</h3>
          <h4>Le Pont</h4>
          <p>
            1678 allée du vieux Pont de Sèvres - 92100 - Boulogne Billancourt
          </p>
          <h4>L'Îlot</h4>
          <p>46 rue d'Orléans - 93600 Aulnay sous-bois</p>
        </div>
        <div className="contact__page__right text__creme">
          <div className="contact__form">
            <h2>NOUS CONTACTER</h2>
            <form autocomplete="off">
              <div className="field">
                <label for="test">Nom</label>
                <input type="text" id="nom" placeholder="Jean" />
              </div>
              <div className="field">
                <label for="prenom">Prénom</label>
                <input type="text" id="prenom" placeholder="Dupont" />
              </div>
              <div className="field mail__input">
                <label for="email">Adresse Mail</label>
                <input
                  type="email"
                  id="email"
                  placeholder="JeanDupont@adressMail.com"
                />
              </div>
              <div className="field">
                <label for="message">Message</label>
                <textarea
                  className="message"
                  id="message"
                  placeholder="Ecrivez nous ici..."
                />
              </div>

              <input type="submit" value="Envoyer" />
            </form>
            <div className="contact__info__box">
              <div className="form__adress">
                <h4>Écrivez-nous</h4>
                <a href="contact@tnf-formation.fr">
                  <i className="ri-mail-line"></i>contact@tnf-formation.fr
                </a>
                <a href="referant-handicap@tnf-formation.fr">
                  <i className="ri-mail-line"></i>
                  referant-handicap@tnf-formation.fr
                </a>
                <a href="inscription@tnf-formation.fr">
                  <i className="ri-mail-line"></i>inscription@tnf-formation.fr
                </a>
              </div>
              <div className="form__number">
                <h4>Appelez-nous</h4>
                <p>Tél : 0144647078</p>
              </div>
            </div>
            <div className="delay__response">
              <p>*Réponse garantie sous 48h</p>
            </div>
          </div>
        </div>
        <button className="croix text__creme" onClick={onClick}>
          <i class="ri-close-fill"></i>
        </button>
      </motion.div>
    </motion.div>
  );

  const onToggleCont = () => {
    setShowCont(!showCont);
  };

  return (
    <React.Fragment>
      <div className="contact__main" onClick={onToggleCont}>
        <div className="letter-image">
          <div className="animated-mail">
            <div className="back-fold"></div>
            <div className="letter">
              <div className="letter-border"></div>
              <div className="letter-title"></div>
              <div className="letter-context"></div>
              <div className="letter-stamp">
                <div className="letter-stamp-inner"></div>
              </div>
            </div>
            <div className="top-fold"></div>
            <div className="body"></div>
            <div className="left-fold"></div>
          </div>
          <div className="shadow"></div>
        </div>
      </div>
      <div className="contact__page">
        {showCont && <Cont onClick={onToggleCont} />}
      </div>
    </React.Fragment>
  );
};
