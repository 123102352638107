import React from "react";
import { Link } from "react-router-dom";
import leftArrow from "../lib/arrow-right.svg";

export const Button = ({ text, to }) => {
  return (
    <div className="button">
      <Link to={to}>
        {text}
        <img src={leftArrow} alt="" />
      </Link>
    </div>
  );
};
