import { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Home } from "./components/pages/Home";
import { About } from "./components/pages/About";
import { Identity } from "./components/pages/Identity";
import { Navigation } from "./components/Navigation";
import { Footer } from "./components/Footer";

import ONM from "./components/pages/ONM";
import Confiance from "./components/pages/Confiance";
import communicationVerbal from "./components/pages/communicationVerbal";
import Infographiste from "./components/pages/Infographiste";
import Cadreur from "./components/pages/Cadreur";

import "remixicon/fonts/remixicon.css";
import DrawingBoard from "./utils/drawingBoard";

import "./css/app.css";

function App() {
  var wb = null;

  // useEffect(() => {
  //   wb = new DrawingBoard();
  //   wb.init(
  //     window.innerWidth -
  //       (window.innerWidth - document.getElementById("root").offsetWidth),
  //     window.innerHeight,
  //     document.getElementById("root")
  //   );
  // }, []);

  return (
    <Router>
      <Navigation />
      <Switch>
        <Route
          path="/formations/operateur-numerique-multimedia"
          component={ONM}
        />
        <Route
          path="/formations/assistant-infographiste-audiovisuel"
          component={Infographiste}
        />
        <Route
          path="/formations/assistant-cadreur-monteur"
          component={Cadreur}
        />

        <Route
          path="/formations/module_API_Confiance_en_soi"
          component={Confiance}
        />

        <Route
          path="/formations/DeveloppementVerbalEtNonVerbal"
          component={communicationVerbal}
        />
        <Route exact path="/identite" component={About} />
        <Route exact path="/a-propos" component={Identity} />
        <Route exact path="/" component={Home} />
      </Switch>

      <Footer />
      <div className="vignette" />
    </Router>
  );
}

export default App;
