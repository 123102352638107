import React, { useEffect } from "react";
import Section from "../../Section";
import FormationModules from "../../sections/FormationModules";
import { HeadingPar } from "../../TextHelper";

export const ModulesAPI = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    if (data.planning !== undefined) {
      console.log(data.planning[0]);
    }
  }, []);

  return (
    <main>
      <Section>
        <h1 className="header__content">{data.name}</h1>
      </Section>

      <Section>
        <HeadingPar heading={data.heading1} par={data.para1} />
        <HeadingPar heading={data.heading2} par={data.para2} />

        {
          <div className="formation__list">
            <h3>Objéctifs spécifiques</h3>
            <ul className="formation__objectifs">
              {data.spec_goals.map((spec) => (
                <li>
                  <p>{spec} </p>
                </li>
              ))}
            </ul>
            <h3>Objéctifs de développement</h3>
            <ul className="formation__objectifs">
              {data.dev_goals.map((dev) => (
                <li>
                  <p>{dev} </p>
                </li>
              ))}
            </ul>
            <h3>Résultats attendus</h3>
            <ul className="formation__objectifs">
              {data.results.map((_result) => (
                <li>
                  <p>{_result}</p>{" "}
                </li>
              ))}
            </ul>
            <h3>Le cursus</h3>
            <p>
              {data.cursus[0].name} : {data.cursus[0].pedagogique}-{" "}
              {data.cursus[0].time}
            </p>
            <p>{data.cursus[0].resume}</p>

            <p>
              {data.cursus[1].name} : {data.cursus[0].pedagogique}-{" "}
              {data.cursus[1].time}
            </p>
            <p>{data.cursus[1].resume}</p>

            <p>
              {data.cursus[2].name} : {data.cursus[0].pedagogique}-{" "}
              {data.cursus[2].time}
            </p>
            <p>{data.cursus[2].resume}</p>

            <p>
              {data.cursus[3].name} : {data.cursus[0].pedagogique}-{" "}
              {data.cursus[3].time}
            </p>
            <p>{data.cursus[3].resume}</p>

            <p>
              {data.cursus[4].name} : {data.cursus[0].pedagogique}-{" "}
              {data.cursus[4].time}
            </p>
            <p>{data.cursus[4].resume}</p>

            <h3>L'équipe pédagogique</h3>
            <ul className="formation__objectifs">
              {data.team.map((_team) => (
                <li>
                  <p>{_team}</p>{" "}
                </li>
              ))}
            </ul>
            <h3>Les dates</h3>
            {data.planning.map((element, i) =>
              element.dates.map((date) => (
                <p>
                  {element.day} {date} {element.month} {element.year}
                </p>
              ))
            )}
            <h3>Le prix</h3>
            <div>{data.budget[0].taux} par heure </div>
            <div>{data.budget[0].heures} de formation</div>
            <div>TOTAL : {data.budget[0].total} euros</div>
          </div>
        }
      </Section>
    </main>
  );
};
