import { useEffect, useState } from "react";

const useScrollInfo = () => {
  const [scrollInfo, setScrollInfo] = useState({ scrollAmountX: 0 });

  useEffect(() => {
    document.addEventListener("scroll", handleScrollInfo);
    return () => {
      document.removeEventListener("scroll", handleScrollInfo);
    };
  });

  const handleScrollInfo = () => {
    setScrollInfo({ scrollAmountY: window.scrollY });
  };

  return scrollInfo;
};

export default useScrollInfo;
