import React, { useEffect, useRef, useState } from "react";
import Section from "../Section";
import { FormationCard } from "../FormationCard";
import { Data } from "../Data";
import { HeadingPar } from "../TextHelper";
import { Image } from "../Image";

import HomeDefilement1 from "../sections/HomeDefilement1";
import HomeDefilement2 from "../sections/HomeDefilement2";
import HomeDefilementPhotos from "../sections/HomeDefilementPhotos";

import onmData from "../../utils/data/onm_formation_data.json";
import spe1Data from "../../utils/data/infographiste_formation_data.json";
import spe2Data from "../../utils/data/cadreur_formation_data.json";
import workshopData1 from "../../utils/data/workshop1.json";
import workshopData2 from "../../utils/data/workshop2.json";

import mainLogo from "../../lib/main-logo.svg";

import illuONM from "../../lib/onm.svg";
import illuSpe1 from "../../lib/Infographiste.svg";
import illuSpe2 from "../../lib/cadreur_monteur.svg";

import illuEspaces from "../../lib/espaces-fond.svg";
import { Button } from "../Button";

const images1 = [
  {
    src: "https://api.maignanb.com/static/tnf-static-images/fill/img-6.jpeg",
    alt: "",
  },
  {
    src: "https://api.maignanb.com/static/tnf-static-images/fill/img-4.jpeg",
    alt: "",
  },
  {
    src: "https://api.maignanb.com/static/tnf-static-images/fill/img-3.jpeg",
    alt: "",
  },
  {
    src: "https://api.maignanb.com/static/tnf-static-images/fill/img-12.jpeg",
    alt: "",
  },
];

const text1 = [
  "Nous développons un portefeuille de formations innovantes facilitatrices d’empowerment numérique.",
  "Nous parions sur le numérique comme vecteur de rebond professionnel pour ceux qui souhaitent être acteurs et communicants.",
];

export const Home = () => {
  const [target, setTarget] = useState();
  const handleSectionRef = (value) => {
    setTarget(value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(window);
  }, []);

  useEffect(() => {
    if (
      window.location.href.split("#").length > 0 &&
      window.location.href.split("#")[1] === "indicateurs" &&
      target !== undefined
    ) {
      window.scrollTo(0, target && target.current.offsetTop - 100);
    }
  }, [target]);

  return (
    <main>
      <Section>
        <div className="header__content">
          <img src={mainLogo} alt="Logo Tremplin Numerique Formation"></img>
        </div>
      </Section>
      <HomeDefilement1 />
      <HomeDefilementPhotos text={text1} images={images1} />

      <Section className="formations">
        <HeadingPar
          heading="DES CURSUS TRANSDISCIPLINAIRES CIBLÉS CRÉATION DE CONTENU POUR LE WEB ET RS"
          par="Associées au développement du web et des réseaux sociaux, nos
          formations visent des compétences numériques et artistiques pour créer
          du contenu."
        />
        <FormationCard
          illustration={illuONM}
          data={onmData}
          to={{
            text: "Découvrir ce cursus",
            link: "/formations/operateur-numerique-multimedia",
          }}
        />
        <FormationCard
          illustration={illuSpe1}
          data={spe1Data}
          to={{
            text: "Découvrir ce cursus",
            link: "/formations/assistant-infographiste-audiovisuel",
          }}
        />
        <FormationCard
          illustration={illuSpe2}
          data={spe2Data}
          to={{
            text: "Découvrir ce cursus",
            link: "/formations/assistant-cadreur-monteur",
          }}
        />
      </Section>
      <Section
        className="formation__data"
        id="indicateurs"
        handleSectionRef={handleSectionRef}
      >
        <Data
          className="data__lg"
          value="33"
          label="Opérateur Numérique Multimédia (ONM)"
        />
        <Data className="data__md" value="98%" label="D'assiduité ONM" />
        <Data
          className="data__md"
          value="81%"
          label="De sorties positives pour les spés"
        />
        <Data
          className="data__sm"
          value="8"
          label="Assistant Infographiste audiovisuelle"
        />
        <Data
          className="data__sm"
          value="9"
          label="Assistant Cadreur/Monteur"
        />
      </Section>
      {/* <Section className="workshops">
        <HeadingPar
          heading="DES WORKSHOPS POUR AGRÉGER DES COMPÉTENCES A LA CARTE"
          par=" Un format idéal de 4 jours pour découvrir ou approfondir des parcours
          de compétences autour des arts plastiques et numériques."
        />
        <FormationCard
          illustration={illuONM}
          data={workshopData1}
          to={{ text: "Découvrir ce workshop", link: "/" }}
        />
        <FormationCard
          illustration={illuONM}
          data={workshopData2}
          to={{ text: "Découvrir ce workshop", link: "/" }}
        />
      </Section> */}
      <HomeDefilement2 />
      <Section className="defilement__photos">
        <div className="section__text">
          <p className="scroll_text">
            Entre living lab, interactivité avec l’outil et développement du
            portage de projets, notre identité pédagogique explore des
            potentiels contemporains.
          </p>
          <Button text="Notre adn" to="/identite" />
        </div>

        <div className="photos__container">
          <Image
            src="https://api.maignanb.com/static/tnf-static-images/fill/img-1.jpeg"
            alt=""
          />
          <Image
            src="https://api.maignanb.com/static/tnf-static-images/fill/img-13.jpeg"
            alt=""
          />
          <Image
            src="https://api.maignanb.com/static/tnf-static-images/fill/img-10.jpeg"
            alt=""
          />
          <Image
            src="https://api.maignanb.com/static/tnf-static-images/fill/img-8.jpeg"
            alt=""
          />
        </div>
      </Section>
      <Section className="ecosysteme">
        <HeadingPar
          heading="UN ÉCOSYSTÈME FACILITATEUR"
          par="Nous avons imaginé un écosystème de formation idéal avec une communauté pédagogique engagée, moderne et passionnée travaillant au sein d’espaces sécurisés, équipés avec des moyens techniques de pointe spécifiques à l’univers professionnel de la formation choisie."
        />
        <Image
          src="https://api.maignanb.com/static/tnf-static-images/fill/img-2.jpeg"
          alt=""
        />
        {/* <div className="espaces__preview">
          <img src={illuEspaces} alt="Nos Espace" />
          <h3>LE PONT</h3>
          <h3>L'ILOT</h3>
        </div> */}
        <Button text="Notre ecosystème" to="/a-propos" />
      </Section>
    </main>
  );
};
