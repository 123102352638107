import React, { useEffect, useState } from "react";
import carteCapacite from "../../lib/TNF_site_cartecapacite.svg";
import carteAttitude from "../../lib/TNF_site_carteattitude.svg";
import carteConnaissance from "../../lib/TNF_site_carteconnaissance.svg";
import carteHabilete from "../../lib/TNF_site_cartehabileté.svg";
import { motion } from "framer-motion";

const _descs = [
  {
    title: "ATTITUDES ",
    desc: "comportements sociaux ou affectifs acquis et mobilisables dans des domaines de la vie professionnelle.",
  },
  {
    title: "CAPACITÉS",
    desc: "opérations mentales, mécanismes de la pensée quand on exerce son intelligence.",
  },
  {
    title: "CONNAISSANCES",
    desc: "informations, notions, procédures acquises, mémorisées et reproductibles.",
  },
  {
    title: "HABILETÉS",
    desc: "perceptions, mouvements, gestes acquis et reproductibles dans un contexte donné",
  },
];

function AboutSkillCards() {
  const [descs, setDescs] = useState(_descs);
  const [index, setIndex] = useState(0);

  return (
    <div className="about__skill__cards">
      <div className="evaluation__card">
        <motion.img
          src={carteAttitude}
          alt="Attitude"
          id="attitude"
          onMouseOver={() => {
            setIndex(0);
          }}
          animate={{ y: 35, scale: index === 0 ? 1.3 : 1.2, rotate: -10 }}
          transition={{
            duration: 0.2,
            ease: "backInOut",
          }}
        />
        <motion.img
          src={carteCapacite}
          alt="Capacite"
          id="capacite"
          onMouseOver={() => {
            setIndex(1);
          }}
          animate={{ scale: index === 1 ? 1.3 : 1.2, rotate: -2.5 }}
          transition={{
            duration: 0.2,
            ease: "backInOut",
          }}
        />
        <motion.img
          src={carteConnaissance}
          alt="Connaissance"
          id="connaissance"
          animate={{ scale: index === 2 ? 1.3 : 1.2, rotate: 2.5 }}
          transition={{
            duration: 0.2,
            ease: "backInOut",
          }}
          onMouseOver={() => {
            setIndex(2);
          }}
        />
        <motion.img
          src={carteHabilete}
          alt="Habileté"
          id="habileté"
          animate={{ y: 35, scale: index === 3 ? 1.3 : 1.2, rotate: 10 }}
          transition={{
            duration: 0.2,
            ease: "backInOut",
          }}
          onMouseOver={() => {
            setIndex(3);
          }}
        />
      </div>
      <div className="desc">
        <h4>{descs[index].title}</h4>
        <p>{descs[index].desc}</p>
      </div>
    </div>
  );
}

export default AboutSkillCards;
