import React from "react";
import { Formation } from "./models/Formation";
import infoData from "../../utils/data/infographiste_formation_data.json";
function Infographiste() {
  return (
    <React.Fragment>
      <Formation data={infoData} />
    </React.Fragment>
  );
}

export default Infographiste;
