import React, { useEffect } from "react";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import Modules from "../pages/models/Modules";
import Section from "../Section";

function FormationModules({ modules }) {
  const { path, url } = useRouteMatch();
  useEffect(() => {
    console.log(modules);
  }, [modules]);

  return (
    <Section className="modules">
      <h2 className="formation__content">CONTENU DE LA FORMATION</h2>
      <div className="modules__navigator">
        <ul className="modules__nav">
          {modules.map((m, i) => (
            <li key={i}>
              <Link
                to={`${url}/modules/${m.name}`}
                className="module__link text__creme"
              >
                {m.name}
                <span
                  className="time__formation"
                  style={{
                    fontSize: 12,
                    backgroundColor: "#222",
                    padding: 6,
                    position: "absolute",
                    top: 15,
                    borderRadius: 22,
                    display: "inline",
                  }}
                >
                  {m.time}
                </span>
              </Link>
            </li>
          ))}
        </ul>
        <Switch>
          {modules.map((m, i) => (
            <Route key={i * 10} path={`${path}/modules/${m.name}`}>
              <Modules module={m} />
            </Route>
          ))}
        </Switch>
      </div>
    </Section>
  );
}

export default FormationModules;
