import React, { useEffect } from "react";
import Section from "../Section";
import { Image } from "../Image";
import { HeadingPar } from "../TextHelper";

import illuInteract from "../../lib/TN_site_illuinteractivite.svg";

import AboutSkillCards from "../sections/AboutSkillCards";

export const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      <Section id="about__header">
        <div className="header__content">
          <h1>L’EXPLORATION COMME ADN PÉDAGOGIQUE</h1>
        </div>
      </Section>
      <Section className="livinglabs">
        <HeadingPar
          heading="DES LIVING-LABS, NOTRE MÉTHODE DE RECHERCHE ET INNOVATION"
          par="Nous faisons confiance aux apprenants et concevons, avec eux et pour eux, des parcours collectifs et individuels performants."
        />
        <Image
          src="https://api.maignanb.com/static/tnf-static-images/fill/img-0.jpeg"
          alt=""
        />
        <Image
          src="https://api.maignanb.com/static/tnf-static-images/fill/img-5.jpeg"
          alt=""
        />
        <p>
          Au sein de ces espaces d'exploration communs, un ensemble coordonné de
          connaissances et de pratiques dessinent une démarche d'apprentissage
          centrée d'avantage sur l'expérience que sur la théorie.
        </p>
      </Section>
      <Section className="section__shifted accelerateur">
        <HeadingPar
          heading="L'INTERACTIVITE, UN ACCELERATEUR D'APPRENTISSAGE"
          par="Nous parions sur le côté ludique des nouvelles technologies où il est question de logiciels et non de « matières », où l’interactivité joue un rôle fondamental pour réussir un processus d’apprentissage."
        />
        <img src={illuInteract} alt="" />
      </Section>
      <Section className="pratique__projet">
        <HeadingPar
          heading="LA PRATIQUE DES PROJETS, UNE DYNAMIQUE PROACTIVE"
          par="Nous mobilisons l’apprenant dans l’acquisition de connaissances, la construction de savoirs et le développement de compétences à travers la réalisation de productions concrètes. 
          Cette possibilité donne lieu à des interactions de tutelle, mais surtout est l'occasion d’analyses réflexives sur l’expérience."
        />
        <Image
          src="https://api.maignanb.com/static/tnf-static-images/fill/img-3.jpeg"
          alt=""
        />
        <p>
          Cette possibilité donne lieu à des interactions de tutelle, mais
          surtout est l'occasion d’analyses réflexives sur l’expérience.
        </p>
      </Section>
      <Section className=" section__shifted portage">
        <div className="portage__title text__orange">
          <h3>Nous sommes convaincus que le portage des projets permet de </h3>
        </div>
        <div className="portage__items text__creme">
          <p>DÉVELOPPER LA COOPERATION</p>
          <p>DÉCOUVIR DE NOUVEAUX SAVOIRS</p>
          <p>SAISIR L'OPPORTUNITÉ DES OBSTACLES </p>
        </div>
      </Section>
      <Section>
        <HeadingPar
          heading="UNE EVALUATION HOLISTIQUE"
          par="Nous identifions des acquis et des manques tout au long du parcours pédagogique suivant 4 axes d’évaluation."
        />
        <AboutSkillCards />
      </Section>
    </main>
  );
};
