import React, { useState, useEffect } from "react";
import { Image } from "../Image";
import Section from "../Section";

import useScrollInfo from "../../utils/hooks/useScroll";

function HomeDefilementPhotos({ images }) {
  const { scrollAmountY } = useScrollInfo();

  const [sectionRef, setSectionRef] = useState();
  const [diference, setDiference] = useState(0);

  useEffect(() => {
    sectionRef && setDiference(scrollAmountY - sectionRef.current.offsetTop);
  }, [scrollAmountY]);

  useEffect(() => {
    // console.log(diference);
  }, [diference]);

  const handleSectionRef = (ref) => {
    setSectionRef(ref);
  };

  return (
    <Section className="defilement__photos" handleSectionRef={handleSectionRef}>
      <div className="section__text">
        <p className="scroll_text">
          Nous développons un portefeuille de formations innovantes
          facilitatrices d’empowerment numérique.
        </p>
        <p className="scroll_text" style={{ display: "none" }}>
          Nous parions sur le numérique comme vecteur de rebond professionnel
          pour ceux qui souhaitent être acteurs et communicants.
        </p>
      </div>
      <div className="photos__container">
        {images.map((image, i) => (
          <Image src={image.src} alt={image.alt} key={i} />
        ))}
      </div>
    </Section>
  );
}

export default HomeDefilementPhotos;
