import { useEffect, useState } from "react";

const useMouseInfo = () => {
  const [mouseInfo, setMouseInfo] = useState({});
  let position = { x: 0, y: 0 };
  let normalizedPos = { x: 0, y: 0 };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseInfo);
    return () => {
      window.removeEventListener("mousemove", handleMouseInfo);
    };
  });

  const handleMouseInfo = (e) => {
    position.x = e.clientX;
    position.y = e.clientY;
    normalizedPos.x = position.x / window.innerWidth;
    normalizedPos.y = position.y / window.innerHeight;

    setMouseInfo((prev) => ({
      ...prev,
      position: position,
      normalizedPos: normalizedPos,
    }));
  };

  return mouseInfo;
};

export default useMouseInfo;
