import React from "react";
import { Formation } from "./models/Formation";
import infoData from "../../utils/data/cadreur_formation_data.json";
function Cadreur() {
  return (
    <React.Fragment>
      <Formation data={infoData} />
    </React.Fragment>
  );
}

export default Cadreur;
