import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Section from "../Section";

import useScrollInfo from "../../utils/hooks/useScroll";

function HomeDefilement1() {
  const { scrollAmountY } = useScrollInfo();

  const [sectionRef, setSectionRef] = useState();
  const [difference, setDiference] = useState(0);

  useEffect(() => {
    sectionRef && setDiference(scrollAmountY - sectionRef.current.offsetTop);
    // console.log(difference);
  }, [scrollAmountY]);

  useEffect(() => {
    // console.log(difference);
  }, [difference]);

  const handleSectionRef = (ref) => {
    setSectionRef(ref);
  };

  return (
    <Section
      className="defilement"
      handleSectionRef={handleSectionRef}
      animate={{
        backgroundColor: difference > 450 ? "#0b0c0cff" : "#0b0c0c00",
        transition: {
          duration: 0.3,
        },
      }}
    >
      <motion.div
        className="growing disc"
        style={{ x: -260 }}
        animate={
          difference > -15 && difference < 500
            ? {
                scale:
                  difference < -300 && difference > 500
                    ? 0
                    : Math.sin(difference / (window.innerWidth / 2)) * 4,
              }
            : {
                scale: difference < -100 && difference > -500 && 0,
              }
        }
      ></motion.div>
      <motion.h2
        className="text__creme"
        style={{
          clipPath: `circle(${
            Math.sin(difference / (window.innerWidth / 2)) * 2000
          }px at center)`,
        }}
      >
        L’EMPOWERMENT <br /> NUMÉRIQUE DANS LA MIRE
      </motion.h2>
    </Section>
  );
}

export default HomeDefilement1;
