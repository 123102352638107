import React from "react";
import { Link } from "react-router-dom";

import logo from "../lib/logo-onblack.svg";
import qualiopi from "../lib/qualiopi.png";
import { Button } from "./Button";

export const Footer = () => {
  return (
    <footer>
      <div className="footer__col">
        <img
          src={logo}
          className="logo"
          alt="Logo Tremplin Numérique Fromation"
        />
        <ul>
          <li>Menu</li>
          <li>
            <Link to="/">Accueil</Link>
          </li>
          <li>
            <Link to="/identite">Notre Identité</Link>
          </li>
          <li>
            <Link to="/a-propos">Notre Ecosysteme</Link>
          </li>
        </ul>
        <ul>
          <li>Nos formations</li>
          <li>
            <Link to="/formations/operateur-numerique-multimedia/modules/Acceuil">
              {" "}
              Opératrice/ Opérateur Numérique Multimédia
            </Link>
          </li>
          <li>
            <Link to="/formations/assistant-infographiste-audiovisuel/modules/Acceuil">
              Assistant Infographiste Audiovisuel
            </Link>
          </li>
          <li>
            <Link to="/formations/assistant-cadreur-monteur/modules/Acceuil">
              Assistant Cadreur/ <br />
              Monteur
            </Link>
          </li>
        </ul>
        <div className="message">
          <p>
            Vous n'êtes pas comme les autres,
            <br /> nous non plus !
          </p>
        </div>
        <img
          src={qualiopi}
          className="qualiopi"
          alt="Logo Tremplin Numérique Fromation"
        />
      </div>
    </footer>
  );
};
